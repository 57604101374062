import React from "react";

export default function TestimonialAutor({ name, position, imgSrc, imgAlt }) {
	return (
		<div className="testimonialAuthor">
			<img
				className="testimonialAuthorPhoto"
				src={imgSrc}
				alt={imgAlt}
				loading="lazy"
			/>
			<div className="testimonialAuthorWrapper">
				<div className="testimonialAuthorName text-dark mb-4">{name}</div>
				<div className="testimonialAuthorPosition text-dark">{position}</div>
			</div>
		</div>
	);
}
