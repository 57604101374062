import React from "react";

import StarIcon from "../images/inlinesvgs/icon-star.svg";

export default function StarRating() {
	return (
		<div className="starRating">
			<StarIcon className="starIcon" />
			<StarIcon className="starIcon" />
			<StarIcon className="starIcon" />
			<StarIcon className="starIcon" />
			<StarIcon className="starIcon" />
		</div>
	);
}
